<template>
  <div >
    <!-- Verified teacher -->
    <div v-if="(verified || alreadyVerified) && !professional" class="confirm-wrapper">
      <img src="../../../assets/icons/icn_email.svg" class="page-icon" alt="Email icon"/>

      <!-- Title and subtitle -->
      <h1 class="auth-title">{{ $t( verifyResponse.approvedTeachers ? 'CONFIRM_TEACHER.TITLE' : 'CONFIRM_TEACHER.TITLE_ADMIN') }}</h1>
      <p class="auth-subtitle page-subtitle">
        {{ $t( verifyResponse.approvedTeachers ? 'CONFIRM_TEACHER.SUBTITLE_1' : 'CONFIRM_TEACHER.SUBTITLE_1_ADMIN') }}
      </p>
      <p class="auth-subtitle page-subtitle">
        {{ $t('CONFIRM_TEACHER.SUBTITLE_2') }}
      </p>

      <button type="button" class="pink-button" @click="redirectToClass()">{{ $t('GO_TO_CLASS') }}</button>
    </div>
    <!-- Verified professional -->
    <div v-else-if="(verified || alreadyVerified) && professional">
      <img src="../../../assets/icons/icn_email.svg" class="page-icon" alt="Email icon"/>

      <!-- Title and subtitle -->
      <h1 class="auth-title">{{ $t('CONFIRM_TEACHER.TITLE_ADMIN') }}</h1>
      <p class="auth-subtitle page-subtitle">
        {{ $t('CONFIRM_TEACHER.SUBTITLE_PROFESSIONAL_1') }}
      </p>
      <p class="auth-subtitle page-subtitle">
        {{ $t('CONFIRM_TEACHER.SUBTITLE_PROFESSIONAL_2') }}
      </p>
      <button type="button" class="pink-button" @click="redirectToClass()">{{ $t('LOGIN.LOGIN') }}</button>
    </div>
    <!-- expired token -->
    <div v-else-if="expiredToken" class="confirm-wrapper">
      <img src="../../../assets/icons/icn_email.svg" class="page-icon" alt="Email icon"/>


      <h1 class="auth-title">{{ $t('CONFIRM_TEACHER.EXPIRED_TOKEN_TITLE') }}</h1>
      <p class="auth-subtitle page-subtitle">
        {{ $t('CONFIRM_TEACHER.EXPIRED_TOKEN_SUBTITLE', {email: verifyResponse.email} ) }}
      </p>
      <button type="button" class="pink-button" @click="resendVerificationEmail(verifyResponse.email)">{{ $t('CONFIRM_TEACHER.RESEND_EMAIL') }}</button>
    </div>
    <!-- Invalid token -->
    <div v-else-if="invalidToken || timeout" class="confirm-wrapper">
      <img src="../../../assets/icons/icn_email.svg" class="page-icon" alt="Email icon"/>

      <!-- Title and subtitle -->
      <h1 class="auth-title">{{ $t('CONFIRM_TEACHER.INVALID_TOKEN_TITLE') }}</h1>
      <p class="auth-subtitle page-subtitle">
        {{ $t('CONFIRM_TEACHER.INVALID_TOKEN_SUBTITLE') }}
      </p>
      <!-- Form -->
      <FormWrapper :send-form-data="validateData" class="page-form-wrapper">
        <template #form-content>
          <!-- Email -->
          <InputField :field-title="`${$t('EMAIL_ADDRESS')}`"
                      :field-name="'email'"
                      :rules="'required'"
                      :type="'email'"
                      :placeholder="$t('EMAIL_ADDRESS')"
                      :cy-selector="'name-input'"
                      :input-icon="require('../../../assets/icons/icn_email.svg')"
                      :api-error-text="apiErrorText"
                      class="auth-form-element"/>
        </template>
        <template #button-submit>
          <button type="submit" class="pink-button auth-button button-send">
            {{ $t('CONFIRM_TEACHER.RESEND_EMAIL') }}
          </button>
        </template>
      </FormWrapper>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { VERIFY_EMAIL, SEND_TEACHER_LOGIN, RESEND_VERIFICATION_EMAIL } from '@/store/modules/auth/actions'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import FormWrapper from '@/components/yo-form/FormWrapper'
import InputField from '@/components/yo-form/InputField'
import ApiHandler from '../../../utils/handlers/ApiHandler'


export default {
  name: 'ConfirmTeacher',
  components: {
    FormWrapper,
    InputField
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const token = route.params.token


    const timeout = ref(false)

    //If no response within a second, say the link was invalid
    setTimeout(() => { timeout.value = true }, 3000)

    const verifyResponse = ref({})

    const verified = computed(() => verifyResponse.value.emailVerified)

    const alreadyVerified = computed(() => verifyResponse && verifyResponse.value.error === 'email_already_verified')

    const invalidToken = computed(() => verifyResponse && verifyResponse.value.error === 'invalid_verify_email_token')

    const expiredToken = computed(() => verifyResponse && verifyResponse.value.error === 'expired_verify_email_token')
    //TODO check if verification actually works.
    const professional = computed(() => verifyResponse && verifyResponse.value.userTypeId === 2)

    function validateData(data) {
      resendVerificationEmail(data.email)
    }

    function resendVerificationEmail(email) {
      store.dispatch(RESEND_VERIFICATION_EMAIL, email).then(response => {
        router.push({ name: ROUTE_NAMES_AUTH.REGISTER_EMAIL_SENT, query: { email } })
      })
    }

    verifyTeacher(token)

    function redirectToClass() {
      router.push({ name: ROUTE_NAMES_AUTH.LOGIN })
    }

    function verifyTeacher(token) {
      store.dispatch(VERIFY_EMAIL, token).then(response => {
        if (response) {
          verifyResponse.value = response.data
        }
      })
    }

    return {
      verifyResponse,
      verified,
      alreadyVerified,
      invalidToken,
      expiredToken,
      redirectToClass,
      validateData,
      resendVerificationEmail,
      professional,
      timeout,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.confirm-wrapper {
  margin: rem(20) auto;
  max-width: rem(481);
}

.page-icon {
  height: auto;
  margin-bottom: rem(48);
  width: rem(140);
}

.page-subtitle {
  line-height: rem(24);
  margin-top: rem(17);

  &:not(:last-of-type) {
    margin-bottom: rem(20);
  }

  &:last-of-type {
    margin-bottom: rem(51);
  }
}
</style>
